import {
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { BsCheck } from 'react-icons/bs'
import { ReactComponent as Retour } from '../assets/Retour.svg'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { CallUs, Formtitle } from '../components'
import { learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import { FormType } from '../utils/sharedTypes'

type RecommandationPropsType = {
  form: FormType
}

const BankTransfer = ({ form }: RecommandationPropsType) => {
  const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
      <Grid item xs={12} md={6} sx={{ mt: { xs: 15, md: 0 } }}>
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{
              height: 80,
              width: 80,
              borderRadius: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BsCheck size="40px" style={{ color: 'white' }} />
          </Grid>
        </Grid>
        <Grid item maxWidth={750} mx={'auto'}>
          <Formtitle title={'Votre dossier est complet'} align="center" />
        </Grid>
        <Grid>
          <TableContainer component={Paper} sx={{ p: 3 }}>
            <Typography sx={{ marginBottom: 1 }}>
              Dès la réception de votre virement sur le RIB ci-dessous, un lien d'accès à notre plateforme
              d'apprentissage vous sera envoyé à {form.data.email}.
            </Typography>
            <Typography sx={{ marginBottom: 2 }}>
              Attention à bien préciser la référence de virement pour ne pas retarder le déblocage de votre formation !
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Compte bancaire</TableCell>
                  <TableCell align="center">Référence</TableCell>
                  <TableCell align="right">Montant</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: 0 }}>
                    <Typography>
                      <b>Titulaire :</b> ELOCE
                      <br />
                      <b>RIB :</b> FR76 1695 8000 0171 9021 6299 256
                      <br />
                      <b>BIC :</b> QNTOFRP1XXX
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                      <Link
                        href="https://walter-resources.s3.amazonaws.com/cgu_cgv/RIB Walter Learning.pdf"
                        target="_blank"
                      >
                        Télécharger...
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ border: 0 }}>
                    <Typography>INSC-{form.data.id}</Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ border: 0 }}>
                    <Typography>
                      {formatter.format(form.data.personal_funding || form.data.default_personal_funding || 0)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          pt: 2,
          pl: { xs: 2, md: 4 },
          display: 'flex',
        }}
      >
        <Grid item container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {location.pathname.includes('/sante') ? (
              <WalterSanteColor
                width="162px"
                onClick={() => {
                  window.open('https://walter-learning.com/sante', '_self')
                }}
              />
            ) : (
              <WalterLearning
                width="180px"
                onClick={() => {
                  window.open('https://walter-learning.com', '_self')
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: 40, mx: 2 }} />
          </Grid>
          <Grid
            item
            sx={{
              // flexGrow: { xs: 1, sm: 0 },
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              location.pathname.includes('/sante')
                ? window.open('https://walter-learning.com/sante', '_self')
                : window.open('https://walter-learning.com', '_self')
            }}
          >
            <Retour />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Retour au site
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={form.data.theme === 'sante' ? santePhoneNumber : learningPhoneNumber} />
      </Grid>
    </Grid>
  )
}

export default BankTransfer
