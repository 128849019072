import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PrivateRoute } from './components'
import { ThemeProvider, Theme, responsiveFontSizes } from '@mui/material'
import { WLtheme, WStheme } from './utils/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import { Login, Admin, Registration, ProviderDashboard, ProviderRegistration, Task } from './pages'
import { ApprenticeshipRoutes, EnterpriseRoutes, RegistrationRoutes } from './routes'
import Hotjar from '@hotjar/browser'

const App = () => {
  const params = useParams()
  const [selectedTheme, setSelectedTheme] = useState<Theme>(WLtheme)
  const [globalId, setGlobalId] = useState<string | undefined>(undefined)
  console.log('params', params)

  useEffect(() => {
    //CHANGE THEME & FAVICON DEPENDING ON URL
    if (window.location.pathname.includes('/sante/') && selectedTheme === WLtheme) {
      setSelectedTheme(WStheme)
      const link: HTMLAnchorElement | null = window.document.querySelector("link[rel~='icon']")
      if (link) {
        link.href = '/WSFavicon.svg'
      }
    }
  }, [])

  useEffect(() => {
    if (globalId) {
      Hotjar.identify(globalId, {
        global_id: globalId,
      })
    }
  }, [globalId])

  useEffect(() => {
    let previousUrl = ''
    const observer = new MutationObserver(function () {
      if (location.href !== previousUrl) {
        previousUrl = location.href
        console.log(`URL changed to ${location.href}`)
        const url = new URL(location.href)
        const global_id = url.searchParams.get('global_id')
        if (!globalId && global_id) {
          console.log('setGlobalId(global_id)', global_id)
          setGlobalId(global_id)
        }
      }
    })
    const config = { subtree: true, childList: true }
    observer.observe(document, config)
  }, [])

  return (
    <ThemeProvider theme={responsiveFontSizes(selectedTheme)}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Router>
          <Routes>
            <Route path="/alternance/*" element={<ApprenticeshipRoutes />} />
            <Route path="/sante?/*" element={<RegistrationRoutes />} />
            <Route path="/entreprise/*" element={<EnterpriseRoutes />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/registration/:id"
              element={
                <PrivateRoute>
                  <Registration />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/sf/task/:task_id"
              element={
                <PrivateRoute>
                  <Task />
                </PrivateRoute>
              }
            />
            <Route path="/providers/:provider_id/" element={<ProviderDashboard />} />
            <Route path="/providers/:provider_id/registration/:registration_id/" element={<ProviderRegistration />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
