import { useTheme, Grid } from '@mui/material'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { Stepper } from '.'
import { FormData, StepType } from '../utils/sharedTypes'

type HeaderPropsType = {
  form: FormData
  steps: StepType[]
}

const Header = ({ form, steps }: HeaderPropsType) => {
  const theme = useTheme()
  return (
    <Grid
      item
      container
      sx={{
        display: { xs: 'block', sm: 'none' },
        pt: 2,
        pl: 2,
        pr: 2,
        background: theme.palette.primary.light,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item container justifyContent="space-between" alignItems="center" sx={{ alignItems: 'baseline' }}>
        {location.pathname.includes('/sante') ? (
          <WalterSanteColor
            width="162px"
            onClick={() => {
              window.open('https://walter-learning.com/sante', '_self')
            }}
          />
        ) : (
          <WalterLearning
            width="180px"
            onClick={() => {
              window.open('https://walter-learning.com', '_self')
            }}
          />
        )}
        <Grid item>
          {form.product?.product_code && (
            <img
              src={`https://walter-production-resources.s3.eu-west-3.amazonaws.com/formations/${form.product.product_code.toLowerCase()}/img2/logotype_black.svg`}
              height="28px"
            />
          )}
        </Grid>
      </Grid>
      <Stepper steps={steps} />
    </Grid>
  )
}

export default Header
