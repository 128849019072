import { Stepper } from '.'
import { Grid, Typography, Rating, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { FormData, StepType } from '../utils/sharedTypes'
import { MdCheck } from 'react-icons/md'

type DrawerPropsType = {
  form: FormData
  steps: StepType[]
}

const Drawer = ({ form, steps }: DrawerPropsType) => {
  const theme = useTheme()
  const location = useLocation()

  return (
    <Grid
      item
      sm={4}
      md={3}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="space-between"
      wrap="nowrap"
      sx={{
        position: 'fixed',
        background: theme.palette.primary.light,
        p: 2,
        pl: 4,
        height: '100vh',
        display: { xs: 'none', sm: 'flex' },
      }}
    >
      <Grid item sx={{ cursor: 'pointer' }}>
        {location.pathname.includes('/sante') ? (
          <WalterSanteColor
            width="162px"
            onClick={() => {
              window.open('https://walter-learning.com/sante', '_self')
            }}
          />
        ) : (
          <WalterLearning
            width="180px"
            onClick={() => {
              window.open('https://walter-learning.com', '_self')
            }}
          />
        )}
      </Grid>
      <Grid item sx={{ flexGrow: 1, pt: 8 }}>
        {form.product?.product_code && (
          <img
            src={`https://walter-resources.s3.eu-west-3.amazonaws.com/formations/${form.product.product_code.toLowerCase()}/img2/logotype_black.svg`}
            width="78%"
            height="50px"
          />
        )}
        <Typography variant="body2">{form.product?.description}</Typography>
        <Stepper steps={steps} />
      </Grid>
      <Grid item container direction="column" sx={{ mb: 4 }}>
        <Grid item container alignItems="center" wrap="nowrap" sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            4.7 sur Google
          </Typography>
          <Rating value={4.7} precision={0.1} readOnly />
        </Grid>
        <Grid item>
          <Grid item container alignItems="center" sx={{ pb: 1 }} wrap="nowrap">
            <MdCheck size="12" style={{ color: theme.palette.success.main, marginRight: 4 }} />
            <Typography variant="body2">Formations 100% en ligne</Typography>
          </Grid>
          <Grid item container alignItems="center" sx={{ pb: 1 }} wrap="nowrap">
            <MdCheck size="12" style={{ color: theme.palette.success.main, marginRight: 4 }} />
            <Typography variant="body2">Par des spécialistes reconnus</Typography>
          </Grid>
          <Grid item container alignItems="center" wrap="nowrap">
            <MdCheck size="12" style={{ color: theme.palette.success.main, marginRight: 4 }} />
            <Typography variant="body2">Sur ordinateur, tablette ou mobile</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Drawer
